

































































































































import { ListingFilter } from 'client-website-ts-library/filters';
import { API, Config, Logger, LogLevel } from 'client-website-ts-library/services';
import {
  Coordinate, GeoCluster, Listing, ListingCategory, ListingSortColumn, ListingStatus, MapSettings, MethodOfSale, Office, PropertyCategory, PropertyType, SortType, WebsiteLevel,
} from 'client-website-ts-library/types';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ObserveVisibility } from 'vue-observe-visibility';

import geoViewport from '@mapbox/geo-viewport';

import { Map } from 'client-website-ts-library/components';
import MultiSelect, { MultiSelectOption } from 'client-website-ts-library/components/MultiSelect.vue';
import { Utils } from 'client-website-ts-library/util';

import ListingCard from './ListingCard.vue';
import Loader from './UI/Loader.vue';
import Column from './Layout/Column.vue';

@Component({
  components: {
    Map,
    MultiSelect,
    ListingCard,
    Loader,
  },
  directives: {
    'observe-visibility': ObserveVisibility,
  },
})
export default class ListingMap extends Vue {
  private readonly id = Utils.GetGuid();

  @Prop()
  private readonly type!: string;

  private searchType: string = this.type;

  private minPrice = '0';

  private maxPrice = '0';

  private minBeds = '0';

  private minBaths = '0';

  private commercialAreaMin = '0';

  private commercialAreaMax = '0';

  private sortMode = 'CreateDateDesc';

  private filter: ListingFilter | null = null;

  private listings: Listing[] = [];

  private clusters: GeoCluster<string>[] = [];

  private moveDebounce?: number;

  @Prop({ required: true })
  private center!: Coordinate;

  @Prop({ required: true })
  private zoom!: number;

  private locationOptions: MultiSelectOption[] = [];

  private commercialTypeOptions: MultiSelectOption[] = [
    {
      Value: PropertyCategory.Offices.toString(),
      Label: 'Offices',
    },
    {
      Value: PropertyCategory.Retail.toString(),
      Label: 'Retail',
    },
    {
      Value: PropertyCategory.ShowroomsBulkyGoods.toString(),
      Label: 'Showroom / Bulky Goods',
    },
    {
      Value: PropertyCategory.MedicalConsulting.toString(),
      Label: 'Medical / Consulting',
    },
    {
      Value: PropertyCategory.CommercialFarming.toString(),
      Label: 'Commercial Farming',
    },
    {
      Value: PropertyCategory.LandDevelopment.toString(),
      Label: 'Land / Development',
    },
    {
      Value: PropertyCategory.HotelLeisure.toString(),
      Label: 'Hotel / Leisure',
    },
    {
      Value: PropertyCategory.IndustrialWarehouse.toString(),
      Label: 'Industrial / Warehouse',
    },
    {
      Value: PropertyCategory.Other.toString(),
      Label: 'Other',
    },
  ];

  private propertyTypeOptions: MultiSelectOption[] = [
    {
      Value: [PropertyType.Duplex, PropertyType.House, PropertyType.Retirement, PropertyType.Villa, PropertyType.Alpine, PropertyType.Terrace, PropertyType.Townhouse].map((v) => v.toString()).join(','),
      Label: 'House',
    },
    {
      Value: [PropertyType.Apartment, PropertyType.Flat, PropertyType.SemiDetached, PropertyType.ServicedApartment, PropertyType.Studio, PropertyType.Unit].map((v) => v.toString()).join(','),
      Label: 'Apartment / Unit',
    },
    {
      Value: [PropertyType.Acreage, PropertyType.Cropping, PropertyType.Dairy, PropertyType.Farmlet, PropertyType.Horticulture, PropertyType.Lifestyle, PropertyType.Livestock, PropertyType.Viticulture].map((v) => v.toString()).join(','),
      Label: 'Rural / Farming',
    },
    {
      Value: [PropertyType.BlockOfUnits, PropertyType.Warehouse, PropertyType.Other].map((v) => v.toString()).join(','),
      Label: 'Other',
    },
  ];

  private mapSettings: MapSettings | null = null;

  private more = false;

  private listingCount = 0;

  private loading = false;

  private moneyFormatter = new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD', minimumFractionDigits: 0 });

  private offices: Office[] = [];

  private hasMap = true;

  private shouldResearch = false;

  private widthQueryList = window.matchMedia('(max-width: 768px)');

  fetch() {
    if (this.filter === null || this.loading) return;

    this.loading = true;

    const filter = new ListingFilter(this.filter);

    if (!this.hasMap) {
      filter.BoundsNorth = '';
      filter.BoundsEast = '';
      filter.BoundsSouth = '';
      filter.BoundsWest = '';
    }

    if (this.searchType === 'commercial' || this.searchType === 'commercial-rent' || this.searchType === 'land-for-sale') {
      filter.PropertyTypes = [];
      filter.MinBedrooms = 0;
      filter.MinBathrooms = 0;
    } else {
      filter.MinLandArea = this.commercialAreaMin;
      filter.MaxLandArea = this.commercialAreaMax;
      filter.PropertyCategories = [];
    }

    API.Listings.SearchMap(filter).then((results) => {
      if (this.mapSettings == null) return;

      if (results.Unclustered) this.listings.push(...results.Unclustered);
      if (results.Clusters) this.clusters.push(...results.Clusters);

      this.listingCount = results.TotalListings;

      this.more = results.More;

      this.mapSettings.Markers = this.clusters.map((c) => ({
        Coordinate: c.WeightedCenter,
        Content: `<div class='listing-map__preview'>
        ${c.Results.map((listingId) => {
          const listing = this.listings.find((l) => l.Id === listingId);

          if (listing) {
            return `<div class='listing-preview' onclick='listingmap.ViewListing("${listing.Id}")'>
              ${listing.Images.length ? `<div class='listing-preview__image' style='background-image:url(${listing.Images[0].Thumbs.Url})'></div>` : ''}
              <div class='listing-preview__details'>
                <h5 class='listing-preview__address'>${listing.Address.MicroAddress}</h5>
                <h6 class='listing-preview__price'>${listing.Price.Price}</h6>
              </div>
            </div>`;
          }

          return '';
        }).join('')}
        ${c.Results.length > 1 ? `<div class='cluster-zoom-prompt' onclick='listingmap.ViewCluster(${c.WeightedCenter.Lat}, ${c.WeightedCenter.Lon})'>View More</div>` : ''}
        </div>`,
      }));

      this.loading = false;

      if (this.shouldResearch) {
        this.shouldResearch = false;
        this.fetch();
      }
    });
  }

  mounted() {
    this.filter = new ListingFilter({
      PageSize: 65,
    });

    const win = window as any;

    /*
    try {
      this.filter.BoundsNorth = this.$route.query.North ? parseFloat(this.$route.query.North as string) : -17.3066987;
      this.filter.BoundsEast = this.$route.query.East ? parseFloat(this.$route.query.East as string) : 167.8511123;
      this.filter.BoundsSouth = this.$route.query.South ? parseFloat(this.$route.query.South as string) : -17.753874;
      this.filter.BoundsWest = this.$route.query.West ? parseFloat(this.$route.query.West as string) : 167.8805086;
    } catch (ex) {
      Logger.Log(LogLevel.Warning, '[ListingMap]', 'Failed to parse bounds from query');
    }
    */

    try {
      this.minBeds = this.$route.query.Beds ? (this.$route.query.Beds as string) : '0';
      this.minBaths = this.$route.query.Baths ? (this.$route.query.Baths as string) : '0';

      this.minPrice = this.$route.query.MinPrice ? (this.$route.query.MinPrice as string) : '0';
      this.maxPrice = this.$route.query.MaxPrice ? (this.$route.query.MaxPrice as string) : '0';
    } catch (ex) {
      Logger.Log(LogLevel.Warning, '[ListingMap]', 'Failed to parse beds, baths, min price and max price from query');
    }

    const mapWrap = this.$refs.mapWrap as HTMLDivElement;

    // const viewport = geoViewport.viewport([parseFloat(this.filter.BoundsWest.toString()), parseFloat(this.filter.BoundsSouth.toString()), parseFloat(this.filter.BoundsEast.toString()), parseFloat(this.filter.BoundsNorth.toString())], [mapWrap.scrollWidth, mapWrap.scrollHeight]);

    this.mapSettings = new MapSettings({
      Interactive: true,
      GreedyZoom: true,
      Zoom: 10,
      Center: {
        Lat: -17.6590125,
        Lon: 168.3323371,
      },
    });

    win.listingmap = {
      ViewListing: (id: string) => {
        this.$router.push(`/listings/${id}`);
      },
      ViewCluster: (lat: number, lon: number) => {
        console.log(lat, lon);

        const map = this.$refs.map as Map;

        const newZoom = Math.min(14.5, Math.round(map.GetZoom() * 1.8));

        map.SetCenter({ Lat: lat, Lon: lon }, newZoom);
      },
    };

    this.updateSearchType();

    this.updateSuburbs().then(() => {
      try {
        if (this.$route.query.Suburbs) {
          const suburbs = (this.$route.query.Suburbs as string).split('|');
          (this.$refs.SuburbMultiSelect as MultiSelect).setValues(suburbs.map((s) => `suburb:${s}`));
          this.filter!.Suburbs = suburbs;
        }
      } catch (ex) {
        Logger.Log(LogLevel.Warning, '[ListingMap]', 'Failed to parse suburb multi select from query');
      }

      try {
        if (this.$route.query.PropertyTypes) {
          const propertyTypes = (this.$route.query.PropertyTypes as string).split('|').join(',');

          const selectedFilterTypes: PropertyType[] = [];
          const selectedDropdownTypes: string[] = [];

          this.propertyTypeOptions.forEach((typeOption) => {
            if (propertyTypes.indexOf(typeOption.Value) !== -1) {
              selectedFilterTypes.push(...typeOption.Value.split(',').map((p) => parseInt(p, 10) as PropertyType));
              selectedDropdownTypes.push(typeOption.Value);
            }
          });

          this.filter!.PropertyTypes = selectedFilterTypes;
          (this.$refs.PropertyTypeMultiSelect as MultiSelect).setValues(selectedDropdownTypes);
        }
      } catch (ex) {
        Logger.Log(LogLevel.Warning, '[ListingMap]', 'Failed to parse property type multi select from query');
      }
    });

    this.widthQueryList.addEventListener('change', this.handleWidthChanged);

    this.hasMap = window.innerWidth > 768;
  }

  handleWidthChanged(e: MediaQueryListEvent) {
    this.hasMap = !e.matches;
  }

  beforeDestroy() {
    this.widthQueryList.removeEventListener('change', this.handleFilterUpdated);
  }

  resetFilter() {
    if (!this.filter) return;

    this.listings = [];
    this.clusters = [];

    this.filter.Page = 1;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleMoved(pos: any) {
    /* eslint-disable eqeqeq */
    if (!this.filter) return;

    let updated = false;

    if (this.filter.BoundsNorth != pos.NE.Lat) {
      this.filter.BoundsNorth = pos.NE.Lat;
      updated = true;
    }
    if (this.filter.BoundsEast != pos.NE.Lon) {
      this.filter.BoundsEast = pos.NE.Lon;
      updated = true;
    }
    if (this.filter.BoundsSouth != pos.SW.Lat) {
      this.filter.BoundsSouth = pos.SW.Lat;
      updated = true;
    }
    if (this.filter.BoundsWest != pos.SW.Lon) {
      this.filter.BoundsWest = pos.SW.Lon;
      updated = true;
    }

    if (updated) {
      this.handleFilterUpdated();
    }
  }

  @Watch('filter.Suburbs')
  @Watch('filter.OrderByStatements')
  handleFilterUpdated() {
    if (!this.filter) return;

    if (this.loading) {
      this.shouldResearch = true;
      return;
    }

    clearTimeout(this.moveDebounce);

    this.moveDebounce = setTimeout(() => {
      this.resetFilter();
      this.updatePath();
      this.fetch();
    }, 1000);
  }

  updatePath() {
    if (this.filter === null) return;

    const query: Record<string, string> = {};

    // Offices
    this.filter.SearchLevel = WebsiteLevel.Office;
    this.filter.SearchGuid = Config.Website.Settings!.WebsiteId;
    // if (this.filter.SearchLevel === WebsiteLevel.Office) query.Offices = ([this.filter.SearchGuid, ...this.filter.SearchGuids]).join('|');

    // Suburbs
    if (this.filter.Suburbs.length) query.Suburbs = this.filter.Suburbs.join('|');

    // Property Types
    if (this.filter.PropertyTypes.length) query.PropertyTypes = this.filter.PropertyTypes.join('|');

    // Listing Categories
    if (this.filter.PropertyCategories.length) query.PropertyCategories = this.filter.PropertyCategories.join('|');

    // Area
    if (this.commercialAreaMin && this.commercialAreaMin !== '0') query.AreaMin = this.commercialAreaMin;
    if (this.commercialAreaMax && this.commercialAreaMax !== '0') query.AreaMax = this.commercialAreaMax;

    // Price
    if (this.minPrice && this.minPrice !== '0') query.MinPrice = this.minPrice;
    if (this.maxPrice && this.maxPrice !== '0') query.MaxPrice = this.maxPrice;

    // Beds & Baths
    if (this.minBeds && this.minBeds !== '0') query.Beds = this.minBeds;
    if (this.minBaths && this.minBaths !== '0') query.Baths = this.minBaths;

    // Sort
    query.Sort = this.sortMode;

    // Geo
    if (this.filter.BoundsNorth) query.North = this.filter.BoundsNorth.toString();
    if (this.filter.BoundsEast) query.East = this.filter.BoundsEast.toString();
    if (this.filter.BoundsSouth) query.South = this.filter.BoundsSouth.toString();
    if (this.filter.BoundsWest) query.West = this.filter.BoundsWest.toString();

    this.$emit('type_updated', this.searchType);

    try {
      this.$router.replace({
        path: `/listings/${this.searchType}`,
        query,
      });
    } catch (ex) {
      console.error(ex);
    }
  }

  @Watch('searchType')
  updateSuburbs(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!this.filter) return;

      const tmpFilter = new ListingFilter(this.filter);

      tmpFilter.Suburbs = [];

      API.Listings.GetSuburbs(tmpFilter).then((suburbs) => {
        this.locationOptions = this.locationOptions.filter((lo) => lo.Value.startsWith('office:'));

        const suburbOpts = suburbs.map((s) => ({ Value: `suburb:${s}`, Label: s }));

        this.locationOptions.push(...suburbOpts);
        resolve(null);
      });
    });
  }

  @Watch('sortMode')
  updateSortMode() {
    if (!this.filter) return;

    switch (this.sortMode) {
      case 'CreateDateAsc':
        this.filter.OrderByStatements = [{ Column: ListingSortColumn.CreateDate, Type: SortType.Asc }];
        break;
      case 'PriceAsc':
        this.filter.OrderByStatements = [{ Column: ListingSortColumn.SalePrice, Type: SortType.Asc }];
        break;
      case 'PriceDesc':
        this.filter.OrderByStatements = [{ Column: ListingSortColumn.SalePrice, Type: SortType.Desc }];
        break;
      case 'SuburbAsc':
        this.filter.OrderByStatements = [{ Column: ListingSortColumn.Suburb, Type: SortType.Asc }];
        break;
      case 'SuburbDesc':
        this.filter.OrderByStatements = [{ Column: ListingSortColumn.Suburb, Type: SortType.Desc }];
        break;
      case 'CreateDateDesc':
      default:
        this.filter.OrderByStatements = [{ Column: ListingSortColumn.CreateDate, Type: SortType.Desc }];
        break;
    }
  }

  @Watch('minPrice')
  @Watch('maxPrice')
  @Watch('minBeds')
  @Watch('minBaths')
  updateOtherSearchParams() {
    if (this.filter === null) return;

    this.filter.MinPrice = this.minPrice && this.minPrice !== '0' ? this.minPrice : '';
    this.filter.MaxPrice = this.maxPrice && this.maxPrice !== '0' ? this.maxPrice : '';

    this.filter.MinBedrooms = this.minBeds && this.minBeds !== '0' ? this.minBeds : '';
    this.filter.MinBathrooms = this.minBaths && this.minBaths !== '0' ? this.minBaths : '';

    this.handleFilterUpdated();
  }

  @Watch('$route.params.type')
  handleRouteUpdate() {
    this.searchType = this.$route.params.type;
    this.minPrice = '0';
    this.maxPrice = '0';
    this.commercialAreaMin = '0';
    this.commercialAreaMax = '0';
  }

  @Watch('searchType')
  updateSearchType() {
    if (this.filter === null) return;

    console.log(this.searchType);

    switch (this.searchType) {
      case 'for-rent':
        this.filter.Categories = [ListingCategory.ResidentialRental, ListingCategory.Rural];
        this.filter.MethodsOfSale = [MethodOfSale.Lease, MethodOfSale.Both];
        this.filter.Statuses = [ListingStatus.Current, ListingStatus.UnderContract];
        break;
      case 'commercial':
        this.filter.Categories = [ListingCategory.Commercial, ListingCategory.CommercialLand, ListingCategory.Business];
        this.filter.Statuses = [ListingStatus.Current, ListingStatus.UnderContract];
        break;
      case 'commercial-rent':
        this.filter.Categories = [ListingCategory.Commercial, ListingCategory.CommercialLand, ListingCategory.Business];
        this.filter.Statuses = [ListingStatus.Current, ListingStatus.UnderContract];
        this.filter.MethodsOfSale = [MethodOfSale.Lease, MethodOfSale.Both];
        break;
      case 'sold':
        this.filter.Categories = [ListingCategory.ResidentialSale, ListingCategory.ResidentialLand, ListingCategory.Rural];
        this.filter.Statuses = [ListingStatus.Sold];
        break;
      case 'leased':
        this.filter.Categories = [ListingCategory.ResidentialRental, ListingCategory.Rural];
        this.filter.MethodsOfSale = [MethodOfSale.Lease, MethodOfSale.Both];
        this.filter.Statuses = [ListingStatus.Leased];
        break;
      case 'land-for-sale':
        this.filter.Categories = [ListingCategory.ResidentialLand];
        this.filter.MethodsOfSale = [MethodOfSale.Sale, MethodOfSale.ForSale, MethodOfSale.Auction, MethodOfSale.Tender, MethodOfSale.EOI, MethodOfSale.Offers, MethodOfSale.Both];
        this.filter.Statuses = [ListingStatus.Current, ListingStatus.UnderContract];
        break;
      case 'for-sale':
      default:
        this.filter.Categories = [ListingCategory.ResidentialSale, ListingCategory.Rural, ListingCategory.ResidentialLand];
        this.filter.MethodsOfSale = [MethodOfSale.Sale, MethodOfSale.ForSale, MethodOfSale.Auction, MethodOfSale.Tender, MethodOfSale.EOI, MethodOfSale.Offers, MethodOfSale.Both];
        this.filter.Statuses = [ListingStatus.Current, ListingStatus.UnderContract];
        break;
    }

    this.handleFilterUpdated();
  }

  loadOffices() {
    API.Franchises.GetOffices(Config.Website.Settings!.WebsiteId).then((offices) => {
      this.offices = offices;

      this.locationOptions.push(...offices.map((office) => ({ Value: `office:${office.Id}`, Label: office.OfficeName })));
    });
  }

  handleLocationsUpdated(values: MultiSelectOption[]) {
    if (!this.filter) return;

    this.filter.Suburbs = values.filter((s) => s.Value.startsWith('suburb:')).map((s) => s.Label);

    const officeIds = values.filter((s) => s.Value.startsWith('office:')).map((o) => o.Value.split(':')[1]);

    if (officeIds.length) {
      this.filter.SearchLevel = WebsiteLevel.Office;
      this.filter.SearchGuid = officeIds.shift()!;
      this.filter.SearchGuids = officeIds;
    } else {
      this.filter.SearchLevel = Config.Website.Settings!.WebsiteLevel;
      this.filter.SearchGuids = [];
      this.filter.SearchGuid = Config.Website.Settings!.WebsiteId;
    }

    this.resetFilter();
    this.updatePath();
    window.location.reload();
  }

  handlePropertyTypesUpdated(values: MultiSelectOption[]) {
    if (!this.filter) return;

    this.filter.PropertyTypes = [];

    for (let i = 0; i < values.length; i += 1) {
      // eslint-disable-next-line radix
      const types = values[i].Value.split(',').map((v: string) => parseInt(v));

      this.filter.PropertyTypes.push(...types);
    }

    this.handleFilterUpdated();
  }

  handleCommercialTypesUpdated(values: MultiSelectOption[]) {
    if (!this.filter) return;

    // eslint-disable-next-line radix
    this.filter.PropertyCategories = values.map((v) => parseInt(v.Value) as PropertyCategory);

    this.handleFilterUpdated();
  }

  loadMore() {
    if (this.loading || !this.more || this.filter === null) return;

    this.filter.Page += 1;

    this.fetch();
  }

  visibilityChanged(visible: boolean) {
    if (visible) this.loadMore();
  }
}
