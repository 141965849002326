











import { AsyncView } from 'client-website-ts-library/plugins';
import { Component, Mixins } from 'vue-property-decorator';

import ListingMap from '../components/ListingMap.vue';

@Component({
  components: {
    ListingMap,
  },
})
export default class Listings extends Mixins(AsyncView) {
  private type = this.$route.params.type || 'for-sale'

  handleTypeUpdated(type: string): void {
    this.$emit('updateView');
  }
}
